.desktopadmin__mt-0 {
  margin-top: 0px !important;
}

.desktopadmin__mt-1 {
  margin-top: 4px !important;
}

.desktopadmin__mt-2 {
  margin-top: 8px !important;
}

.desktopadmin__mt-3 {
  margin-top: 16px !important;
}

.desktopadmin__mb-0 {
  margin-bottom: 0px !important;
}

.desktopadmin__mb-1 {
  margin-bottom: 4px !important;
}

.desktopadmin__mb-2 {
  margin-bottom: 8px !important;
}

.desktopadmin__mb-3 {
  margin-bottom: 16px !important;
}
